import React, { FC } from 'react';
import { QueryClient } from '@tanstack/react-query';
import {
    PersistQueryClientProvider,
    removeOldestQuery,
} from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
});

export const persister = createSyncStoragePersister({
    storage: typeof window === 'undefined' ? undefined : window.sessionStorage,
    retry: removeOldestQuery,
});

export const QueryClientProvider: FC = ({ children }) => (
    <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}>
        {children}
    </PersistQueryClientProvider>
);
