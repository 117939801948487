exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-app-help-jsx": () => import("./../../../src/pages/app/help.jsx" /* webpackChunkName: "component---src-pages-app-help-jsx" */),
  "component---src-pages-auth-just-giving-tsx": () => import("./../../../src/pages/auth/just-giving.tsx" /* webpackChunkName: "component---src-pages-auth-just-giving-tsx" */),
  "component---src-pages-blog-community-index-jsx": () => import("./../../../src/pages/blog/community/index.jsx" /* webpackChunkName: "component---src-pages-blog-community-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-startup-index-jsx": () => import("./../../../src/pages/blog/startup/index.jsx" /* webpackChunkName: "component---src-pages-blog-startup-index-jsx" */),
  "component---src-pages-blog-tech-index-jsx": () => import("./../../../src/pages/blog/tech/index.jsx" /* webpackChunkName: "component---src-pages-blog-tech-index-jsx" */),
  "component---src-pages-blog-think-piece-index-jsx": () => import("./../../../src/pages/blog/think-piece/index.jsx" /* webpackChunkName: "component---src-pages-blog-think-piece-index-jsx" */),
  "component---src-pages-charities-index-tsx": () => import("./../../../src/pages/charities/index.tsx" /* webpackChunkName: "component---src-pages-charities-index-tsx" */),
  "component---src-pages-complaints-procedure-jsx": () => import("./../../../src/pages/complaints-procedure.jsx" /* webpackChunkName: "component---src-pages-complaints-procedure-jsx" */),
  "component---src-pages-email-verification-jsx": () => import("./../../../src/pages/email-verification.jsx" /* webpackChunkName: "component---src-pages-email-verification-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-jsx": () => import("./../../../src/pages/join.jsx" /* webpackChunkName: "component---src-pages-join-jsx" */),
  "component---src-pages-our-team-jsx": () => import("./../../../src/pages/our-team.jsx" /* webpackChunkName: "component---src-pages-our-team-jsx" */),
  "component---src-pages-pdf-collaboration-agreement-jsx": () => import("./../../../src/pages/PDF/collaboration-agreement.jsx" /* webpackChunkName: "component---src-pages-pdf-collaboration-agreement-jsx" */),
  "component---src-pages-pdf-good-cause-pack-jsx": () => import("./../../../src/pages/PDF/good-cause-pack.jsx" /* webpackChunkName: "component---src-pages-pdf-good-cause-pack-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-saltedge-renew-success-jsx": () => import("./../../../src/pages/saltedge-renew-success.jsx" /* webpackChunkName: "component---src-pages-saltedge-renew-success-jsx" */),
  "component---src-pages-saltedge-success-jsx": () => import("./../../../src/pages/saltedge-success.jsx" /* webpackChunkName: "component---src-pages-saltedge-success-jsx" */),
  "component---src-pages-schools-jsx": () => import("./../../../src/pages/schools.jsx" /* webpackChunkName: "component---src-pages-schools-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-third-sector-digital-innovation-of-the-year-jsx": () => import("./../../../src/pages/third-sector-digital-innovation-of-the-year.jsx" /* webpackChunkName: "component---src-pages-third-sector-digital-innovation-of-the-year-jsx" */),
  "component---src-pages-whatsapp-jsx": () => import("./../../../src/pages/whatsapp.jsx" /* webpackChunkName: "component---src-pages-whatsapp-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-charity-charity-tsx": () => import("./../../../src/templates/charity/charity.tsx" /* webpackChunkName: "component---src-templates-charity-charity-tsx" */)
}

